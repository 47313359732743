import { firebase } from "../firebase";

export const submitVerify = async (
  id: string,
  additionalInfo: string = "",
  address1: string = "",
  address2: string = "",
  city: string = "",
  state: string = "",
  postalCode: string = "",
  firstName: string = "",
  lastName: string = "",
  websiteURL: string = "",
  email: string = "",
  phone: string = "",
  legalBusinessName: string = ""
) => {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser?.getIdToken(true);
  const body = {
    id,
    additionalInfo,
    firstName,
    lastName,
    city,
    state,
    postalCode,
    address1,
    email,
    phone,
    websiteURL,
    legalBusinessName
  };
  if (address2 && address2.length > 0) {
    body["address2"] = address2;
  }

  const response = await fetch(`cs/verify`, {
    body: JSON.stringify(body),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    }
  });

  const dt = await response.json();
  if (!response.ok || (response.status > 200 && response.status < 600)) {
    throw new Error(dt.error || response.statusText);
  }

  return;
};
