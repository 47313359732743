import { db } from "firebase";
import { Account, PhoneNumbersTabType } from "types";

export const getPhoneNumbers = async (type: PhoneNumbersTabType): Promise<Account[]> => {
  switch (type) {
    case "pending": {
      const accounts = await db
        .collection("accounts")
        .where("phoneConfig.status", "==", "pending")
        .get();
      return (accounts.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data()
        };
      }) as Account[]).sort((a, b) => {
        return (
          (b.phoneConfig?.pendingDate?.toDate().getTime() || 1) - (a.phoneConfig?.pendingDate?.toDate().getTime() || 1)
        );
      });
    }
    case "submitted": {
      const accounts = await db
        .collection("accounts")
        .where("phoneConfig.status", "==", "submitted")
        .get();
      return (accounts.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data()
        };
      }) as Account[]).sort((a, b) => {
        return (
          (a.brandRegistration?.submissionDate?.toDate().getTime() || 1) -
          (b.brandRegistration?.submissionDate?.toDate().getTime() || 1)
        );
      });
    }
    case "failed": {
      const accounts = await db
        .collection("accounts")
        .where("phoneConfig.status", "==", "failed")
        .where("plan", "!=", "free")
        .get();
      return (accounts.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data()
        };
      }) as Account[]).sort((a, b) => {
        return (
          (a.phoneConfig?.failedDate?.toDate().getTime() || 1) - (b.phoneConfig?.failedDate?.toDate().getTime() || 1)
        );
      });
    }
    case "free": {
      const accounts = await db
        .collection("accounts")
        .where("phoneConfig.status", "in", ["verified", "free", "failed", "pending", "submitted"])
        .where("plan", "==", "free")
        .get();
      return (accounts.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data()
        };
      }) as Account[]).sort((a, b) => {
        return (a.renewalDate?.toDate().getTime() || 1) - (b.renewalDate?.toDate().getTime() || 1);
      });
    }
  }
};
