import { Timestamp } from "firebase/firestore";
// ! Type definitions for CS app, and CS API, should be in sync with csAPI/types and cs/src/types

export type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  plan: string;
  role: string;
  accountId: string;
  businessId: string;
  csSettings?: {
    isMFAEnabled?: boolean;
    isMFAVerified?: boolean;
    otpAuthUrl?: string;
    otpBase32?: string;
    trustedDevices?: string[];
  };
  authMetadata?: {
    lastSignInTime?: Date | string;
    creationTime?: Date | string;
    lastRefreshTime?: Date | string;
  };
};

export type CurrentUser = User & {
  claims?: {
    auth_time?: number;
  };
};

export type Account = {
  id: string;
  name: string;
  notificationLimit: number;
  origin?: string;
  originalTransactionId?: string;
  type: string;
  plan: string;
  visitCount: number;
  status: string;
  expires?: string;
  expiredDate: string;
  renewalDate?: Timestamp;
  autoPay?: boolean;
  customerSource?: string;
  phoneConfig?: {
    status?: string;
    smsProvider?: string;
    phoneType?: string;
    messagesRestricted?: boolean;
    gracePeriod?: Date | string | Timestamp;
    pendingDate?: Timestamp;
    failedDate?: Timestamp;
    verifiedDate?: Date | string | Timestamp;
    phoneNumber?: string;
    declineReasonDescription?: string;
  };
  brandRegistration?: {
    legalBusinessName?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    websiteURL?: string;
    phone?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    submissionDate?: Timestamp;
  };
};

export type Business = {
  id: string;
  accountId: string;
  name: string;
  type: string;
  address?: {
    address1?: string;
    address2?: string;
    city?: string;
    country?: string;
    geo?: {
      lat: number;
      lng: number;
    };
    postal?: string;
    state?: string;
  };
  settings: {
    timezone: string;
    selfCheckIn?: boolean;
    enableReservations?: boolean;
    selfBooking?: boolean;
    reserveWithGoogle?: boolean;
  };
};

export type Aggregate = {
  id: string;
  businessId: string;
  accountId: string;
  waiting: number;
  served: number;
  reservationsServed: number;
  partiesServed: number;
  locationName: string;
  lastUpdated: Date | string | Timestamp;
  address?: Business["address"];
};

export type AlgoliaObject = {
  objectID: string;
  path: string;
  lastmodified: any;
};

export enum CSSearchType {
  User = "user",
  Account = "account",
  Business = "business"
}

export type CSSearchAccount = {
  type: CSSearchType.Account;
  account: Account;
  locations: Business[];
  users: User[];
};

export type CSSearchBusiness = {
  type: CSSearchType.Business;
  business: Business;
  users: User[];
};

export type CSSearchUser = {
  type: CSSearchType.User;
  user: User;
  account: Account;
  locations: Business[];
};

export enum CSAutocompleteType {
  Users = "users",
  Accounts = "accounts",
  Businesses = "businesses"
}

export type Responses = {
  csSearchAccount: CSSearchAccount | CSSearchBusiness | CSSearchUser;
  csAutocomplete: {
    [key in CSAutocompleteType]: {
      id: string;
      title: string;
      subtitle: string;
    }[];
  };
  createCustomToken: {
    token: string;
  };
  generateOTP: {
    base32: string;
    otpAuthUrl: string;
  };
  validateOTP: {
    otpValid: boolean;
  };
};

export type PhoneNumbersTabType = "pending" | "submitted" | "failed" | "free";
