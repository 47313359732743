import { Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { Input, Select, Option } from "@material-tailwind/react";
import { ChangeEventHandler } from "react";

export const displayDate = (date: Date | string | Timestamp | undefined) => {
  if (!date) return "";
  if (date instanceof Date) return DateTime.fromJSDate(date).toFormat("MM/dd/yyyy");
  if (typeof date === "string") return DateTime.fromJSDate(new Date(date)).toFormat("MM/dd/yyyy");
  return DateTime.fromJSDate(
    new Timestamp(date.seconds || (date as any)._seconds, date.nanoseconds || (date as any)._nanoseconds).toDate()
  ).toFormat("MM/dd/yyyy");
};

export const getGoogleSearchUrl = (name: string, account: any): string => {
  const formattedName: string = name.replace(/ /g, "+");
  const city: string = (account?.brandRegistration?.city || "").replace(/ /g, "+");
  const state: string = (account?.brandRegistration?.state || "").replace(/ /g, "+");
  return `https://www.google.com/search?q=${encodeURIComponent(formattedName)}+${city}+${state}`;
};

export const displayInput = (id: string, label: string, onChange: ChangeEventHandler, defaultValue: string = "") => {
  return (
    <Input
      id={id}
      variant="outlined"
      defaultValue={defaultValue}
      label={label}
      placeholder={label}
      onChange={onChange}
    />
  );
};

export const displaySelect = (id: string, options: any[], setValue: any) => {
  return (
    <Select
      id={id}
      variant="outlined"
      label="Select a name to submit"
      value={options[0].value}
      onChange={val => setValue(val)}
    >
      {options.map(option => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};
export const displayLink = ({ url, label, internal }: { url?: string; label?: string; internal: boolean }) => {
  if (!url) return label || "";

  if (internal) {
    return (
      <Link className="text-sm hover:underline text-blue-600 hover:text-light-blue-500" to={url}>
        {label}
      </Link>
    );
  }

  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer noopener"
      className="text-sm hover:underline text-blue-600 hover:text-light-blue-500"
    >
      {label}
    </a>
  );
};

export const displayBoolean = (value: boolean | undefined) => {
  return value ? "Yes" : "No";
};
