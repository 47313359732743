import { Button, Typography } from "@material-tailwind/react";
import Table from "components/Table";
import { environment } from "configs/environment";
import { capitalize } from "lodash";
import { createSearchParams, useNavigate } from "react-router-dom";
import { routes } from "routes";
import { Account, Business, User } from "types";
import { getBusinessAddress } from "util/address";
import { displayBoolean, displayDate, displayLink, getGoogleSearchUrl } from "util/text";

export const AccountSearchLocationsTable = ({ locations }: { locations: Business[] }) => {
  const navigate = useNavigate();
  return (
    <Table
      items={locations}
      onRowDoubleClick={location => {
        navigate({
          pathname: routes.app.accounts.path,
          search: createSearchParams({ locationId: location.id }).toString()
        });
      }}
      columns={[
        { header: "Name", accessor: location => location.name },
        { header: "Type", accessor: location => location.type },
        {
          header: "Address",
          accessor: location => getBusinessAddress(location)
        },
        {
          header: "Time Zone",
          accessor: location => location.settings?.timezone
        },
        {
          header: "Actions",
          accessor: location => (
            <Button
              onClick={() => {
                navigate({
                  pathname: routes.app.accounts.path,
                  search: createSearchParams({ locationId: location.id }).toString()
                });
              }}
              variant="text"
              color="blue"
            >
              View
            </Button>
          )
        }
      ]}
    />
  );
};

export const AccountSearchUsersTable = ({ users, locations }: { users: User[]; locations: Business[] }) => {
  const navigate = useNavigate();
  return (
    <Table
      items={users}
      onRowDoubleClick={user => {
        navigate({
          pathname: routes.app.accounts.path,
          search: createSearchParams({ userId: user.id }).toString()
        });
      }}
      columns={[
        { header: "Name", accessor: user => [user.firstName, user.lastName].filter(Boolean).join(" ") },
        { header: "Email", accessor: user => user.email },
        {
          header: "Role",
          accessor: user => capitalize(user.role).replaceAll("_", " ")
        },
        {
          header: "Primary Location",
          accessor: user => locations.find(l => l.id === user.businessId)?.name
        },
        {
          header: "Actions",
          accessor: user => (
            <Button
              onClick={() => {
                navigate({
                  pathname: routes.app.accounts.path,
                  search: createSearchParams({ userId: user.id }).toString()
                });
              }}
              variant="text"
              color="blue"
            >
              View
            </Button>
          )
        }
      ]}
    />
  );
};

export const AccountSearchDetailsUL = ({
  details
}: {
  details: {
    [key: string]: string | React.ReactNode;
  };
}) => {
  return (
    <ul className="flex flex-col gap-4 p-0">
      {Object.keys(details).map((el, key) => (
        <li key={key} className="flex items-center gap-4">
          <Typography variant="small" color="blue-gray" className="font-semibold capitalize">
            {el}:
          </Typography>
          {typeof details[el] === "string" ? (
            <Typography variant="small" className="font-normal text-blue-gray-500">
              {details[el]}
            </Typography>
          ) : (
            details[el]
          )}
        </li>
      ))}
    </ul>
  );
};

export const AccountSearchAccountDetails = ({ account }: { account: Account }) => {
  return (
    <AccountSearchDetailsUL
      details={{
        "Account Id": displayLink({
          url: routes.app.accounts.path + "?accountId=" + account?.id,
          label: account?.id,
          internal: true
        }),
        Name: displayLink({
          label: account?.name,
          url: getGoogleSearchUrl(account.name || "", account),
          internal: false
        }),
        Type: account?.type,
        Plan:
          account?.origin === "stripe" && account?.originalTransactionId
            ? displayLink({
                url: `https://dashboard.stripe.com${
                  import.meta.env.MODE === "production" ? "" : "/test"
                }/subscriptions/${account?.originalTransactionId}`,
                label: account?.plan,
                internal: false
              })
            : account?.plan,
        "Auto Pay": displayBoolean(account?.autoPay),
        "Customer Source": account?.customerSource,
        Origin: account?.origin,
        "Renewal Date": displayDate(account?.renewalDate),
        Expires: displayDate(account?.expires),
        Expired: displayDate(account?.expiredDate),
        Status: account?.status,
        "Visit Count": account?.visitCount?.toLocaleString("en-US"),
        "Notification Limit": account?.notificationLimit?.toLocaleString("en-US"),
        Firebase: displayLink({
          url: `${environment.firestoreUrl}~2Faccounts~2F${account.id}`,
          label: "Firebase",
          internal: false
        })
      }}
    />
  );
};

export const AccountSearchLocationDetails = ({ location }: { location?: Business }) => {
  return (
    <AccountSearchDetailsUL
      details={{
        "Business Id": location?.id,
        "Account Id": displayLink({
          url: routes.app.accounts.path + "?accountId=" + location?.accountId,
          label: location?.accountId,
          internal: true
        }),
        Name: location?.name,
        Type: location?.type,
        Address: getBusinessAddress(location),
        "Time Zone": location?.settings.timezone,
        Firebase: displayLink({
          url: `${environment.firestoreUrl}~2Fbusinesses~2F${location?.id}`,
          label: "Firebase",
          internal: false
        })
      }}
    />
  );
};
