import { ClipboardDocumentCheckIcon, CurrencyDollarIcon, XCircleIcon, ClockIcon } from "@heroicons/react/24/outline";
import { Button, Card, CardBody } from "@material-tailwind/react";
import { getPhoneNumbers } from "api/phoneNumbers";
import { CardEmptyState } from "components/Cards/CardEmptyState";
import { CardLoadingState } from "components/Cards/CardLoadingState";
import Table from "components/Table";
import { useFetch } from "hooks/useFetch";
import { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { routes } from "routes";
import { Account, PhoneNumbersTabType } from "types";
import { CustomCardHeader as Header } from "components/Cards/CustomCardHeader";
import { CardBackground } from "components/Cards/CardBackground";
import { displayBoolean } from "util/text";

const tabs: {
  value: PhoneNumbersTabType;
  label: string;
  Icon: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
      title?: string | undefined;
      titleId?: string | undefined;
    } & React.RefAttributes<SVGSVGElement>
  >;
}[] = [
  { value: "submitted", label: "Submitted", Icon: ClipboardDocumentCheckIcon },
  { value: "pending", label: "Pending", Icon: ClockIcon },
  { value: "failed", label: "Failed", Icon: XCircleIcon },
  { value: "free", label: "Free", Icon: CurrencyDollarIcon }
];

const PhoneNumbersTable = ({
  tab,
  data,
  isLoading
}: {
  tab: PhoneNumbersTabType;
  data: Account[] | null;
  isLoading: boolean;
}) => {
  const navigate = useNavigate();

  const getTableDefinition = (tab: PhoneNumbersTabType) => {
    const tableDef = [
      { header: "Name", accessor: account => account.name },
      { header: "Phone Number", accessor: account => account?.phoneConfig?.phoneNumber },
      { header: "Plan", accessor: account => account.plan },
      { header: "Auto Pay", accessor: account => displayBoolean(account.autoPay) },
      { header: "Status", accessor: account => account?.phoneConfig?.status },
      {
        header: "Actions",
        accessor: account => (
          <Button
            onClick={() => {
              navigate({
                pathname: routes.app.accounts.path,
                search: createSearchParams({ accountId: account.id }).toString()
              });
            }}
            variant="text"
            color="blue"
          >
            View
          </Button>
        )
      }
    ];
    if (tab === "submitted") {
      tableDef.unshift({
        header: "Submitted Date",
        accessor: account => account?.brandRegistration?.submissionDate?.toDate().toLocaleDateString()
      });
    } else if (tab === "pending") {
      tableDef.unshift({
        header: "Submitted to Carrier",
        accessor: account => account?.phoneConfig?.pendingDate?.toDate().toLocaleDateString()
      });
    } else if (tab === "failed") {
      tableDef.unshift({
        header: "Failed Date",
        accessor: account => account?.phoneConfig?.failedDate?.toDate().toLocaleDateString()
      });
    } else if (tab === "free") {
      tableDef.unshift({
        header: "Expired Date",
        accessor: account => account?.renewalDate?.toDate().toLocaleDateString()
      });
    }
    return tableDef;
  };

  if (isLoading) {
    return <CardLoadingState />;
  }

  if (!data?.length) {
    return <CardEmptyState message="No numbers found" />;
  }

  return (
    <Table
      items={data}
      onRowDoubleClick={account => {
        navigate({
          pathname: routes.app.accounts.path,
          search: createSearchParams({ accountId: account.id }).toString()
        });
      }}
      columns={getTableDefinition(tab)}
    />
  );
};

const PhoneNumbersPage = () => {
  const [tab, setTab] = useState<PhoneNumbersTabType>("submitted");
  const { data, isLoading } = useFetch(() => getPhoneNumbers(tab), [tab]);
  return (
    <>
      <CardBackground />
      <Card className="mx-3 -mt-16 mb-6 border border-blue-gray-100 lg:mx-4">
        <CardBody className="p-4">
          <Header
            tab={tab}
            setTab={setTab}
            tabs={tabs}
            subtitle={`${data?.length} accounts found`}
            title={tabs.find(t => t.value === tab)?.label || ""}
          />
          <PhoneNumbersTable tab={tab} data={data} isLoading={isLoading} />
        </CardBody>
      </Card>
    </>
  );
};

export default PhoneNumbersPage;
